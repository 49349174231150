export default {
    "auth": {
        "register": "Зарегистрироваться",
        "authorization": "Авторизация на портале",
        "no_account": "Еще нет учетной записи?",
        "your_email": "Ваш e-mail",
        "enter_email": "Введите e-mail",
        "your_password": "Ваш пароль",
        "enter_password": "Введите пароль",
        "login": "Войти на портал",
        "forgot_password": "Забыли пароль?",
        "cloud_product_title": "Облачный продукт «КОННЕКТ» платформы gos24.kz",
        "cloud_product_description": "Современная и профессиональная система для грамотного планирования и эффективного управления задачами государственных учреждений и предприятий различных форм собственности",
        "local_software": "Отечественная программная разработка, имеющая подтверждение на соответствие нормам информационной безопасности РК",
        "field_required": "Это поле обязательно для заполнения",
        "enter_valid_email": "Дұрыс email енгізіңіз",
        "required_sym": "Максимум {sym} символов",
        "authorisation_error": "Авторизация қатесі",
        "password_recovery": "Восстановление пароля",
        "enter_email_recovery": "Введите свой email, который вы указывали при регистрации, и мы отправим вам ссылку на восстановление пароля.",
        "login_portal": "Авторизация на портале",
        "recover_by_email": "Восстановить по E-mail",
        "recover_by_phone": "Восстановить по номеру телефона",
        "password_recovery_meta": "Восстановление пароля | Gos24.КОННЕКТ",
        "recover_password": "Восстановить пароль",
        "recovery_link_sent": "Ссылка для восстановления пароля отправлена на ваш email",
        "fill_required_fields": "Пожалуйста, заполните обязательные поля",
        "code_verification": "Проверка кода",
        "sms_code_placeholder": "Код из sms",
        "continue": "Продолжить",
        "back": "Назад",
        "resend_code": "Отправить код",
        "resend_code_after": "Отправить код повторно через:",
        "create_password": "Придумайте свой пароль",
        "create_strong_password": "Придумайте надежный пароль для входа в ваш аккаунт",
        "create_strong_password_placeholder": "Придумайте надежный пароль",
        "repeat_password_placeholder": "Повторите пароль",
        "save_password": "Сохранить пароль",
        "passwords_do_not_match": "Пароли не совпадают",
        "password_changed_successfully": "Вы успешно сменили пароль",
        "restore_by_phone": "Восстановить по номеру телефона",
        "your_phone": "Ваш телефон",
        "send": "Отправить",
        "invalid_input": "Заполнено не верно",
        "password_recovery_title": "Восстановление пароля по номеру телефона",
        "registration_title": "Регистрация",
        "step_info_title": "Информация об организации",
        "step_info_description": "Введите БИН вашей организации и мы автоматически подгрузим информацию о названии и иерархической структуре подчинённости",
        "step_verification_title": "Верификация",
        "step_verification_description": "Подтвердите свою личность для защиты аккаунта. На указанный адрес электронной почты будет отправлено сообщение, содержащее код подтверждения. Введите его ниже, чтобы закончить регистрацию",
        "step_personal_info_title": "Личная информация",
        "step_personal_info_description": "Пожалуйста, укажите Ваше имя и фамилию. Чтобы облегчить коммуникацию с коллегами, укажите свою должность в организации",
        "label_contractor_bin": "БИН организации",
        "label_contractor_name": "Наименование организации",
        "button_back": "Назад",
        "button_next": "Далее",
        "placeholder_phone_email": "Ваш телефон / E-mail",
        "placeholder_phone": "Ваш телефон",
        "placeholder_email": "Ваш E-mail",
        "error_bin_length": "БИН должен состоять из 12 символов",
        "error_field_required": "Обязательно для заполнения",
        "error_bin_not_found": "Указанный БИН отсутствует в базе",
        "title_create_password": "Придумайте свой пароль",
        "description_create_password": "Придумайте надежный пароль для входа в ваш аккаунт",
        "label_password": "Пароль",
        "label_confirm_password": "Повторите пароль",
        "placeholder_enter_password": "Введите пароль",
        "placeholder_repeat_password": "Повторите пароль",
        "title_check_code": "Проверка кода",
        "placeholder_code_sms_email": "Код из sms или e-mail",
        "button_send_code": "Отправить код",
        "label_resend_code": "Отправить код повторно через:",
        "title_thank_you": "Благодарим за регистрацию!",
        "message_registration_pending": "Ваша заявка на регистрацию организации находится на модерации, профиль временно заблокирован. После процедуры модерации ваш профиль будет разблокирован.",
        "button_login": "Войти",
        "label_last_name": "Фамилия",
        "placeholder_last_name": "Введите фамилию",
        "label_first_name": "Имя",
        "placeholder_first_name": "Введите имя",
        "label_middle_name": "Отчество",
        "placeholder_middle_name": "Введите отчество",
        "label_job_title": "Ваша должность",
        "placeholder_job_title": "Введите вашу должность",
        "agreement_text": "Согласен",
        "agreement_link": "с политикой конфиденциальности",
        "button_register": "Зарегистрироваться",
        "num_error": "Номер заполнен некорректно",
        "label_email": "Email",
        "label_confirm_code": "Введите код подтверждения",
        "placeholder_code": "Код из sms или e-mail",
        "button_send_confirmation_code": "Отправить код подтверждения на указанный email",
        "message_code_sent": "Отправили код подтверждения на email {{ email }}",
        "phone_or_mail": "Введите телефон / E-mail",
        "email_reg_next": "Для продолжение регистрации вы должны подтвердить указанный email",
        "email_reg_error": "E-mail введён некорректно",
        "portal_reg_title": "Регистрация на портале | Gos24.КОН"
    }
}